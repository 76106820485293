import React from 'react';
import { NavLink } from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var img1 = require('./../../images/our-mission-img-2.png');
var img2 = require('./../../images/background/bg-4.png');
var img3 = require('./../../images/background/bg-site.png');
var img4 = require('./../../images/mission.jpg');

class OurMission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            phoneNumber:'',
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        fetch('https://crmapi.bigboymachines.com/api/BBMSite/SendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
                phoneNumber: this.state.phoneNumber,
            }),
        })
            .then(data => {
                console.log('Got success!', data);
                // Reset the form
                form.reset();
                // Clear the state values
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    phoneNumber: '',
                });
                toast.success('Mail Successfully Sent', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            })
            .catch(error => {
                console.error('The error is:', error);
                // Handle errors
            });
    }

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding mission-outer-section  p-t80 p-b30 bg-gray bg-no-repeat bg-right-center" style={{ backgroundImage: 'url(' + img1 + '), url(' + img2 + ')' }}>
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Mission</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row row-right">
                                <div className="col-md-4 col-sm-6 missiom-weight mission-margin-mm">
                                    <div className="mission-left bg-white m-b30 p-a30 bg-no-repeat bg-bottom-left misson-set col-xxl-12" style={{ backgroundImage: 'url(' + img3 + ')' }}>
                                        <h3 className="m-t0"><span className="font-weight-100"> Our Purpose and</span><br />Mission Statement</h3>
                                        <p>Our mission is to provide the industry with reliable and high-quality used cranes at competitive prices.We are committed to offering exceptional customer service and building long-lasting relationships based on trust and integrity.</p>
                                        {/*<ul className="list-angle-right anchor-line">*/}
                                        {/*    <li><NavLink to={"/services"}>Construction Management</NavLink></li>*/}
                                        {/*    <li><NavLink to={"/services"}>Pre Construction Services</NavLink></li>*/}
                                        {/*    <li><NavLink to={"/services"}>Contract Administration</NavLink></li>*/}
                                        {/*    <li><NavLink to={"/services"}>Implementation</NavLink></li>*/}
                                        {/*    <li><NavLink to={"/services"}>Leed consultation</NavLink></li>*/}
                                        {/*</ul>*/}
                                        <div className="text-right">
                                            <NavLink to="" onClick={(e) => e.preventDefault()} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <div className="mission-mid bg-no-repeat bg-cover m-b30" style={{ backgroundImage: 'url(' + img4 + ')' }} />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="contact-home1-left bg-dark p-a30 m-b0">
                                        <h3 className="text-white m-t0"><span className="font-weight-100">Get In</span> Touch</h3>
                                        <form className="cons-contact-form2 form-transparent" method="post"  onSubmit={this.handleSubmit}>
                                            <div className="input input-animate">
                                                {/* <label htmlFor="name">Name</label> */}
                                                <input type="text" name="username" id="name" onChange={event => {this.setState({name : event.target.value})}} required placeholder="Name"/>
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                {/* <label htmlFor="email">Email</label> */}
                                                <input name="email" type="email" required="True" onInvalid={event => event.target.setCustomValidity('Please enter Correct Email Address')}
                                                           onInput={event => event.target.setCustomValidity('')}
                                                            onChange={event => {this.setState({email : event.target.value})}} className="form-control"   placeholder="Email"  />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                {/* <label htmlFor="Phone">Phone</label> */}
                                                <input name="phone" type="text"  onChange={event => {this.setState({phoneNumber : event.target.value})}} required className="form-control" placeholder="Phone" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                {/* <label htmlFor="message">Message</label> */}
                                                <textarea name="message" onChange={event => {this.setState({message : event.target.value})}} rows={4} className="form-control " required placeholder="Message" defaultValue={""} />
                                                <span className="spin" />
                                            </div>
                                            <div className="text-center p-t10">
                                                <button type="submit" className="site-button btn-effect ">
                                                    Submit Now
                    </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">*/}
                    {/*    <strong>Mission</strong>*/}
                    {/*</div>*/}
                </div>
                <ToastContainer />
            </>
        );
    }
};

export default OurMission;
