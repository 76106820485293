import React from 'react';
import Navigation from '../Common/Navigation';
import { NavLink } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import Mission from "../Pages/AppointmentPopUp";
import { ToastContainer, toast } from 'react-toastify';

var bnr = require('./../../images/background/bg-5.png');

class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = {logo: require('./../../images/BBMWebLogo.png')};
        // this.state = {logo: require('./../../images/logo.png')};
    }


    state = { isSearchActive: false, isQuoteActive: false };

    handleSearchToggle = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive });
    };

    handleQuoteToggle = () => {
        this.setState({ isQuoteActive: !this.state.isQuoteActive });
    };

    componentDidMount() {

        const handleScroll = () => {
            const offset = window.scrollY;

            const stickyheader = document.querySelector('.sticky-header ');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');

            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        }

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
           this.setState({ logo: logopath }); 
        };
    }

    render() {
        const isSearchActive = this.state.isSearchActive;
        const isQuoteActive = this.state.isQuoteActive;
        
        return (
            <>

                <header className="site-header header-style-1">
                    <div className="top-bar bg-gray">
                        <div className="container">
                            <div className="row">
                                <div className="mt-topbar-left clearfix">
                                    <ul className="list-unstyled e-p-bx pull-right">
                                        <li><i className="fa fa-envelope" />biz@bigboymachines.com</li>
                                        <li><i className="fa fa-phone" />(+91) 773-7066-666
</li>
                                        
                                    </ul>
                                </div>
                                {/*<div className="mt-topbar-right clearfix">*/}
                                {/*    <button type="button"  className="button-width" data-toggle="modal" data-target="#appointmentModal">*/}
                                {/*        <div className="site-button">Make an Appointment</div>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="sticky-header main-bar-wraper">
                        <div className="main-bar bg-white">
                            <div className="container">
                                <div className="logo-header">
                                    <div className="logo-header-inner logo-header-one">
                                        <NavLink to={"./"}>
                                            <img src={this.state.logo} alt="BBM" />
                                        </NavLink>
                                    </div>
                                </div>
                                {/* NAV Toggle Button */}
                                <button data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggle collapsed">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                </button>
                                {/* ETRA Nav */}
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <NavLink to={"#"} onClick={this.handleSearchToggle}> 
                                            <i className="fa fa-search" />
                                        </NavLink>
                                    </div>
                                    <div className="extra-cell">
                                        <NavLink to={"#"} className="contact-slide-show" onClick={this.handleQuoteToggle}><i className="fa fa-angle-left arrow-animation" /></NavLink>
                                    </div>
                                </div>
                                {/* ETRA Nav */}
                                {/* Contact Nav */}
                                <div className="contact-slide-hide " style={{ backgroundImage: 'url(' + bnr + ')', right: isQuoteActive ? '0px' : '-500px' }}>
                                    <div className="contact-nav">
                                    <NavLink to={"#"} className="contact_close" onClick={this.handleQuoteToggle}>×</NavLink>
                                        <div className="contact-nav-form p-a30">
                                            <div className="contact-info   m-b30">
                                                <div className="mt-icon-box-wraper center p-b30">
                                                    <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>(+91) 773-7066-666
</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper center p-b30">
                                                    <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>biz@bigboymachines.com</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper center p-b30">
                                                    <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Address info</h5>
                                                        <p>Office no 528, Vyapar Bhavan, <br/>49,P D Mello Road, Masjid East
                                                            Mumbai 400009</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="full-social-bg">
                                                <ul>
                                                    <li><NavLink to={"#"} className="facebook"><i className="fa fa-facebook" /></NavLink></li>
                                                    <li><NavLink to={"#"} className="google"><i className="fa fa-google" /></NavLink></li>
                                                    <li><NavLink to={"#"} className="instagram"><i className="fa fa-instagram" /></NavLink></li>
                                                    <li><NavLink to={"#"} className="tumblr"><i className="fa fa-tumblr" /></NavLink></li>
                                                    <li><NavLink to={"#"} className="twitter"><i className="fa fa-twitter" /></NavLink></li>
                                                    <li><NavLink to={"#"} className="youtube"><i className="fa fa-youtube" /></NavLink></li>
                                                </ul>
                                            </div>
                                            <div className="text-center">
                                                <h4 className="font-weight-600">©  2022 Big Boy Machines</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* SITE Search */}
                                <div id="search" className={isSearchActive ? "open" : null}>
                                    <span className="close" onClick={this.handleSearchToggle} />
                                    <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                        <div className="input-group">
                                            <input defaultValue="" name="q" type="search" placeholder="Type to search" />
                                            <span className="input-group-btn"><button type="button" className="search-btn"><i className="fa fa-search arrow-animation" /></button></span>
                                        </div>
                                    </form>
                                </div>
                                {/* MAIN Vav */}
                                <Navigation />
                            </div>
                        </div>
                    </div>
                        <div id="appointmentModal" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                {/* Modal content*/}
                                <div className="modal-content">
                                    <div className="modal-header bg-secondry">
                                        <button type="button" className="close" data-dismiss="modal">×</button>
                                        <h4 className="modal-title text-white">Book Your Appointment</h4>
                                    </div>
                                    <div className="modal-body">
                                        <form id="demo-form" className="form-horizontal mb-lg" noValidate>
                                            <div className="form-group mt-lg">
                                                <label className="col-sm-3 control-label">Name</label>
                                                <div className="col-sm-9">
                                                    <input name="name" className="form-control" placeholder="Type your name..." required type="text" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Email</label>
                                                <div className="col-sm-9">
                                                    <input name="email" className="form-control" placeholder="Type your email..." required type="email" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Phone</label>
                                                <div className="col-sm-9">
                                                    <input name="phone" className="form-control" placeholder="Phone..." type="text" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Description</label>
                                                <div className="col-sm-9">
                                                    <textarea rows={5} className="form-control" placeholder="Type your description..." required defaultValue={""} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="site-button btn-effect text-uppercase button-sm letter-spacing-2 m-r15" data-dismiss="modal">Close</button>
                                        <button type="submit" className="site-button btn-effect text-uppercase button-sm letter-spacing-2">Submit </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </header>

            </>
        );
    };
};

export default Header;
