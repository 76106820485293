import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Trade Machines`,
        icon: require('./../../images/icon/excavator.png'),
        description: 'We Sell quality used machines at very reasonable prices.',
    },
    {
        count: 2,
        title: 'Machines on Hire',
        icon: require('./../../images/icon/renovation.png'),
        description: 'Our crane rental services are designed to meet the diverse needs of our clients, with a focus on delivering top-quality equipment and experienced operators.',
    },
    {
        count: 3,
        title: 'Spare parts',
        icon: require('./../../images/icon/toolbox.png'),
        description: 'We provide a comprehensive selection of spare parts to our clients, helping them keep their machines running smoothly.',
    },
    {
        count: 4,
        title: 'Tech Help',
        icon: require('./../../images/icon/compass.png'),
        description: 'From troubleshooting and repairs to routine maintenance and inspections, our expert technicians are always available to help.',
    },
];

var img1 = require('./../../images/background/bg-6.png');

class OurServices extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript('./assets/js/masonary.js');
    }

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-b50 square_shape2">
                    <div className="section-content">
                        <div className="Service-half-top p-t80 bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-white">
                                    <div className="mt-separator-outer separator-left">
                                        <div className="mt-separator">
                                            <h2 className="text-white text-uppercase sep-line-one">
                                                <span className="font-weight-300 text-primary">Our</span> Services
                                            </h2>
                                        </div>
                                    </div>
                                    <h3>
                                        Elevate Your Operations with Comprehensive Services: Reliable Trading, Flexible Hiring, and Quality Spare Parts for All Your Lifting and Construction Needs.
                                    </h3>
                                </div>
                                {/* TITLE END */}
                            </div>
                        </div>
                        <div className="services-half-bottom">
                            <div className="container">
                                <div className="row">
                                    {services.map((item, index) => {
                                        const elementClass = index === 0 ? 'first-element-class' : '';
                                        return (
                                            <div className={'col-md-3 col-sm-6 ' + elementClass} key={index}>
                                                <div className={'mt-icon-box-wraper m-b30 ' + elementClass}>
                                                    <div className={'relative icon-count-2 bg-gray p-a30 p-tb50 min-height-460 min-height-530 min-height-450 ' + elementClass}>
                                                        <span className="icon-count-number">{item.count}</span>
                                                        <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                              <span className="icon-cell">
                                <img src={item.icon} alt="" />
                              </span>
                                                        </div>
                                                        <div className="icon-content">
                                                            <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                            <p>{item.description}</p>
                                                            <NavLink to="/services" onClick={(e) => e.preventDefault()} className="site-button-link" data-hover="Read More">
                                                                Read More <i className="fa fa-angle-right arrow-animation" />
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default OurServices;
