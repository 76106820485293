import 'react-phone-number-input/style.css'
import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';
import { FaBeer, BsYoutube, FaYoutubeSquare } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input'
var bgimage = require('./../../images/background/bg-site.png');



class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = {logo: require('./../../images/BBMWebLogoB.png')};
        this.state = {
            name: '',
            phoneNumber:'',
        }
    }
    
    updateFooterLogo = (updatedlogo) => {this.currentstate.logo = updatedlogo;}
     equipmentList = [
        { capacity: '220ton', brand: 'XCMG' },
        { capacity: '100ton', brand: 'XCMG, SANY' },
        { capacity: '80ton', brand: 'Zoomlion, XCMG' },
        { capacity: '130ton', brand: 'XCMG' },
        { capacity: '200ton', brand: 'Zoomlion' },
      ];
     divStyle = {
        marginTop: '47px', // Set the margin-top to 47 pixels
        marginLeft: '7px', // Set the margin-top to 47 pixels
      };
     ddStyle = {
        marginBottom: '7px', // Set the margin-top to 47 pixels
      };
      handlePhoneNumberChange = (value) => {

        this.setState({
          phoneNumber: value,
          
        });
      };
      
      handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        fetch('https://crmapi.bigboymachines.com/api/BBMSite/SendWhatsapp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.name,
                
                phoneNumber: this.state.phoneNumber,
            }),
        })
            .then(data => {
                console.log('Got success!', data);
                // Reset the form
                form.reset();
                // Clear the state values
                this.setState({
                    name: '',
                    phoneNumber: '',
                });
if(data == "Message Sent"){


                toast.success('Subscribed!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });}
                else{

                
                const errorMessage = "Not subscribed! Try Again later " ;
                toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });}
            })
            .catch(error => {
                console.error('The error is:', error);
                // Handle errors
                
            });
    }
    render() {
        
        return (
            <>
                <footer className="site-footer footer-large  footer-dark	footer-wide">
                    <div className="container call-to-action-wrap bg-no-repeat bg-center" style={{ backgroundImage: 'url('+bgimage+')' }}>
                        <div className="p-a30 bg-primary footer-min-height ">
                            <div className="row">
                                <div className="col-md-8 col-sm-8">
                                    <div className="call-to-action-left text-black">
                                        <h4 className="text-uppercase m-b10 m-t0">Get the latest updates – Subscribe to our WhatsApp!</h4>
                                        <span>Never miss a beat from BBM— subscribe  to our WhatsApp updates now.</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <div className="call-to-action-right">
                                        <div className="widget_newsletter">
                                            <div className="newsletter-bx">
                                                <form role="search"  onSubmit={this.handleSubmit}  >
                                                    <div className="input-group">
                                                        <input name="name" required className="form-control " onChange={event => {this.setState({name : event.target.value})}} style={this.ddStyle} placeholder="ENTER YOUR NAME" type="text" />
                                                        <div className="input-group">
{/* 
{ console.log(this.state.phoneNumber)} */}

                                                        
                                                        
<PhoneInput
  
      country="IN"
      value={this.state.phoneNumber}
      placeholder="ENTER YOUR NUMBER"
      onChange={(event) => this.handlePhoneNumberChange(event) } /></div>
                                                      {/* <input name="phoneNumber" onChange={event => {this.setState({phoneNumber : event.target.value})}} className="form-control" placeholder="ENTER YOUR NUMBER" type="text" /> */}
                                                        <span className="input-group-btn">
                                                            <button type="submit" className="site-button"style={this.divStyle}><i className="fa fa-paper-plane-o" /></button>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                            <NavLink to={"./"}><img src={this.currentstate.logo} alt="" /></NavLink>
                                        </div>
                                        <p className="max-w400">A working crane doesn't just move steel; it shifts the balance of progress.</p>
                                        <ul className="social-icons  mt-social-links">
                                            <li><NavLink to={"#"} className="fa fa-google" /></li>
                                            <li><NavLink to={"https://www.instagram.com/big.boy.machines_/"} className="fa fa-instagram" /></li>
                                            <li><NavLink to={"https://www.facebook.com/people/Big-Boy-Machines-Pvt-Ltd/100092293306944/"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"https://www.youtube.com/channel/UCiM-R9cj0L1Rq-INAfpowYw"} className="fa fa-youtube" /></li>
                                            <li><NavLink to={"https://www.linkedin.com/in/big-boy-machines-4bab66278/"} className="fa fa-linkedin" /></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Contact Us</h4>
                                        <ul className="widget_address">
                                            <li>Big Boy Machines Pvt Ltd <br/>Office no 528, Vyapar Bhavan,<br/> 49, P D Mello Road, Masjid East<br/>Mumbai  400009</li>
                                            <li>biz@bigboymachines.com</li>
                                            <li>(+91) 773-7066-666
</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Useful links</h4>
                                        <ul>
                                            <li><NavLink to={"/about"} onClick={(e) => e.preventDefault()}>About</NavLink></li>
                                            <li><NavLink to={"/services"} onClick={(e) => e.preventDefault()}>Services</NavLink></li>
                                            <li><NavLink to={"/blog-grid"} onClick={(e) => e.preventDefault()}>Inquiry</NavLink></li>
                                            <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* TAGS */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget recent-posts-entry-date">
                                        <h4 className="widget-title">Our Top selling Cranes</h4>
                                        <div className="widget-post-bx">
                                            {/* <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                                <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                                    <strong className="p-date">24</strong>
                                                    <span className="p-month">Mar</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="mt-post-info">
                                                    <div className="mt-post-header">
                                                        <h6 className="post-title"><NavLink to={"/post-sidebar"}>On these beams, we’re building dreams.</NavLink></h6>
                                                    </div>
                                                    <div className="mt-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 28</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                             <ol>
        {this.equipmentList.map((item, index) => (
          <li key={index} style={this.ddStyle}>
            <strong>Capacity:</strong> {item.capacity}; <strong>Brand:</strong> {item.brand}
          </li>
        ))}
      </ol>
                                            {/* <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                                <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                                    <strong className="p-date">30</strong>
                                                    <span className="p-month">Jan</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="mt-post-info">
                                                    <div className="mt-post-header">
                                                        <h6 className="post-title"><NavLink to={"/post-sidebar"}>We’ll be a sensation for your next renovation</NavLink></h6>
                                                    </div>
                                                    <div className="mt-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 29</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* NEWSLETTER */}
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="mt-footer-bot-center">
                                    <span className="copyrights-text">© 2022 Your Company. Designed By Clover It Services Pvt Ltd</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/*<Switcher updateFooterLogo={this.updateFooterLogo.bind(this)}/>*/}

            </>
        );
    };
};

export default Footer;
