import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
    { label: "XCMG", filter: ".cat-1" },
    { label: "Zoomlion", filter: ".cat-4" },
    { label: "SANY", filter: ".cat-2" },
    { label: "Crawler Cranes", filter: ".cat-3" },
    { label: "German Brands", filter: ".cat-5" },
    { label: "Boom Lifts", filter: ".cat-6" }
];

const projects = [
    {
        image: require('./../../images/projects/portrait/BBMSany1.jpg'),
        title: 'SANY',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/projects/portrait/BBMSany2.jpg'),
        title: 'SANY',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/projects/portrait/BBMSany3.jpg'),
        title: 'SANY',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/projects/portrait/BBMSany4.jpg'),
        title: 'SANY',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/projects/portrait/BoomLift2.jpg'),
        title: 'Boom Lifts',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-6'
    },
    {
        image: require('./../../images/projects/portrait/BoomLift3.jpg'),
        title: 'Boom Lifts',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-6'
    },
    {
        image: require('./../../images/projects/portrait/BoomLift4.jpg'),
        title: 'Boom Lifts',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-6'
    },
    {
        image: require('./../../images/projects/portrait/CrawlerCrane1.jpg'),
        title: 'Crawler crane',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/projects/portrait/CrawlerCrane2.jpg'),
        title: 'Crawler Crane',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/projects/portrait/CrawlerCrane3.jpg'),
        title: 'Crawler Crane',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/projects/portrait/CrawlerCrane4.jpg'),
        title: 'Crawler Crane',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/projects/portrait/XCMG1.jpg'),
        title: 'XCMG',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/portrait/XCMG2.jpg'),
        title: 'XCMG',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/portrait/XCMG3.jpg'),
        title: 'XCMG',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/portrait/XCMG4.jpg'),
        title: 'XCMG',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/portrait/BoomLift1.jpg'),
        title: 'Boom Lift',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-6'
    },
    {
        image: require('./../../images/projects/portrait/LIEBHERR1.jpg'),
        title: 'LIEBHERR',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/portrait/LIEBHERR2.jpg'),
        title: 'LIEBHERR',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/portrait/LIEBHERR3.jpg'),
        title: 'LIEBHERR',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/portrait/LIEBHERR4.jpg'),
        title: 'LIEBHERR',
        address: 'Mumbai',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/portrait/Zoomlion2.jpg'),
        title: 'Zoomlion',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/portrait/Zoomlion3.jpg'),
        title: 'Zoomlion',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/portrait/Zoomlion4.jpg'),
        title: 'Zoomlion',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/portrait/Zoomlion1.jpg'),
        title: 'Zoomlion',
        address: 'Mobile Truck Crane, Mumbai',
        width: 'col-md-3',
        filter: 'cat-4'
    }
]

class OurProject extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 square_shape2 ">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> PORTFOLIO</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
                                {/* COLUMNS 1 */}
                                <div className="stamp col-md-3 col-sm-6 m-b30">
                                    <div className="bg-gray p-a30">
                                        <div className="filter-wrap">
                                            <ul className="filter-navigation masonry-filter text-uppercase">
                                                <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                                {filters.map((item, index) => (
                                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* COLUMNS 2 */}
                                {projects.map((item, index) => (
                                   <div key={index} className={`${item.filter} masonry-item ${item.width} col-sm-6 m-b30`}>
                                        <div className="mt-box   image-hover-block">
                                            <div className="mt-thum-bx">
                                                <img src={item.image} alt=""/>
                                            </div>
                                            <div className="mt-info  p-t20 text-white">
                                                <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                                                <p className="m-b0">{item.address}</p>
                                            </div>
                                            {/* <NavLink to="/Rental-Cranes"></NavLink> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Projects</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default OurProject;
