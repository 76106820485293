import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

import bnr1 from './../../images/background/bg-5.png';

function Specialization() {
    const firstImageRef = useRef(null);

    const handleMouseOver = () => {
        firstImageRef.current.classList.add('hide-figcaption');
    };

    const handleMouseOut = () => {
        firstImageRef.current.classList.remove('hide-figcaption');
    };

    return (
        <>
            <style>
                {`.hide-figcaption .figcaption {
          display: none;
        }`}
            </style>
            <div className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1" style={{ backgroundImage: `url(${bnr1})` }}>
                <div className="container max-width-1350">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6 m-b30" ref={firstImageRef}>
                                <div className="image-effect-slideone">
                                    <img src={require('../../images/pic1.jpg')} alt="" />
                                    <div className="figcaption">
                                        <h4>Trade</h4>
                                        <p>Acquire Premium Cranes from Big Boy Machines.</p>
                                        <i className="link-plus bg-primary" />
                                        <NavLink to="/Trade"><i className="link-plus bg-primary" /></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                                <div className="image-effect-one hover-shadow" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                    <img src={require('../../images/pic2.jpg')} alt="" />
                                    <div className="figcaption">
                                        <h4>Hire</h4>
                                        <p>Experience Top-Notch Crane Rentals with us</p>
                                        <NavLink to="/Rental-Cranes"><i className="link-plus bg-primary" /></NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                                <div className="image-effect-one hover-shadow" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                    <img src={require('../../images/img-33.jpg')} alt="" />
                                    <div className="figcaption bg-dark">
                                        <h4>Spare Parts</h4>
                                        <p>The Best In Class Spare Parts availability and Technical Support</p>
                                        <i className="link-plus bg-primary" />
                                        {/* <NavLink to="/Rental-Cranes"><i className="link-plus bg-primary" /></NavLink> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-6 m-b30">
                                <div className="mt-box our-speciallization-content">
                                    <h3 className="m-t0"><span className="font-weight-100">We are Expert</span> <br />of Selling, Renting and support</h3>
                                    <p>Our unique selling proposition is the ability to provide our clients with a comprehensive solution, offering crane sales, rentals, and unparalleled customer support.</p>
                                    <NavLink to="/services" className="site-button btn-effect">Know More</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Specialization;
